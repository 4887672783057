/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Image from "next/image";
import {
  Breadcrumbs,
  Colors,
  Heading,
  HeadingLevel,
  Link,
  Spacer,
  Text,
} from "@zapier/design-system";

import imgIntegrations from "public/img/workflow-element/integrations-to-your-app.png";
import imgZapEditor from "public/img/workflow-element/zap-editor-in-your-product.png";
import imgZapManagement from "public/img/workflow-element/manage-zaps-in-your-product.png";
import imgZapTemplates from "public/img/workflow-element/zap-templates-in-your-product.png";
import mediaQuery from "src/utils/mediaQuery";
import { useWorkflowElement } from "./hooks/useWorkflowElement";
import { AppSelector } from "../AppSelector";

const styleColumns = css({
  display: "flex",
  flexDirection: "column",
  [mediaQuery.large]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 30,
  },
});

const styleColumnElement = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const styleWorkflowContainer = css`
  max-width: 960px;
  padding: 20px 0 40px 0;
  margin-inline: auto;
`;

const styleFullWidthAppSelector = css`
  background-color: ${Colors.GrayWarm2};
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding-block: 25px;
`;

interface WorkflowAppSelectorProps {
  workflowURL: URL;
}

export function WorkflowAppSelector({ workflowURL }: WorkflowAppSelectorProps) {
  const workflowElement = useWorkflowElement();
  const { clientId, integrationId, serviceSlug } =
    workflowElement.elementConfig ?? workflowElement.defaultConfig;
  const dontShowAppSelector = clientId && integrationId && serviceSlug;

  // [Workflow Element] Don't show the app selector if the user is
  // already logged in and has an app
  if (dontShowAppSelector) {
    return null;
  }

  return (
    <div css={styleWorkflowContainer}>
      <nav aria-label="Page hierarchy">
        <Breadcrumbs size="small">
          <Link href="/partner/solutions">Partner Solutions</Link>
          <span aria-current="page">Workflow</span>
        </Breadcrumbs>
      </nav>
      <Text tag={Heading} type="PageHeader" margin="20px 0 48px 0">
        All of Zapier, in a few lines of code
      </Text>
      <Text tag="p" type="ParagraphHeader1">
        The Workflow Element is a prebuilt UI component that offers the quickest
        and easiest way to surface your Zapier integration directly within your
        own product. With just a few lines of code and no API required, users
        can discover, create, and edit workflows directly within your app.
      </Text>
      <Spacer height={40} />
      <div css={styleFullWidthAppSelector}>
        <div
          css={{
            maxWidth: 960,
            margin: "0 auto",
          }}
        >
          <Text tag={Heading} type="SectionHeader" margin="0 0 25px 0">
            Choose your app and get started!
          </Text>
          <AppSelector
            continueURL={workflowURL}
            data-testid="appSelector:workflowElement"
            isWorkflowElement={true}
            isFullWidth={true}
          />
        </div>
      </div>
      <Spacer height={40} />
      <HeadingLevel>
        <Text tag={Heading} type="SectionHeader" margin="0 0 40px 0">
          Features:
        </Text>
        <div css={styleColumns}>
          <div css={styleColumnElement}>
            <Text tag={Heading} type="ParagraphHeader3" margin="0 0 20px 0">
              Surface Zap templates in your product
            </Text>

            <Image
              src={imgZapTemplates}
              alt=""
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </div>
          <div css={styleColumnElement}>
            <Text tag={Heading} type="ParagraphHeader3" margin="0 0 20px 0">
              Enable 7000+ integrations to your app
            </Text>

            <Image
              src={imgIntegrations}
              alt=""
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </div>
          <div css={styleColumnElement}>
            <Text tag={Heading} type="ParagraphHeader3" margin="0 0 20px 0">
              Display Zap editor in your product
            </Text>

            <Image
              src={imgZapEditor}
              alt=""
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </div>
          <div css={styleColumnElement}>
            <Text tag={Heading} type="ParagraphHeader3" margin="0 0 20px 0">
              Manage Zaps directly in your product
            </Text>

            <Image
              src={imgZapManagement}
              alt=""
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </div>
        </div>
        <Spacer height={40} />
        <Text tag={Heading} type="SectionHeader" margin="30px 0 10px">
          Choose your app and get started!
        </Text>
      </HeadingLevel>
      <AppSelector
        continueURL={workflowURL}
        data-testid="appSelector:workflowElement"
        isWorkflowElement={true}
      />
    </div>
  );
}
