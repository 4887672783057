/** @jsxRuntime classic */
/** @jsx jsx */

// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
//
// Please test this code sample in Codesandbox when updating it!
//
// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

import { jsx } from "@emotion/react";
import { Text } from "@zapier/design-system";
import { FC, Fragment } from "react";
import CodeBlock from "src/components/CodeBlock";
import { kebabToCamelCase } from "src/utils/kebabToCamelCase";
import {
  AppParamsPlaceholder,
  CodeExampleProps,
  styleExampleLayout,
} from "./CodeExample";
import { QuacCodeAlert } from "./QuacCodeAlert";
import { CodeBlockWithPlaceholders } from "../CodeBlockWithPlaceholders";
import { CodePlaceholder } from "./CodePlaceholder";

// Thanks @kaitrin for writing the Angular example :D
export const CodeExampleAngular: FC<CodeExampleProps> = ({
  params,
  element,
}) => {
  return (
    <div css={styleExampleLayout}>
      <Text tag="h2" type="SectionHeader">
        A solution for Angular
      </Text>
      <Text tag="p">
        Custom elements (web components) can be used with Angular. Use the
        following snippets as an example.
      </Text>
      <CodeBlock
        title="HTML (<head>)"
        code={`\
<!-- See the "Vanilla JS" solution if you'd rather use JS to load these -->
<script type="module" src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js"></script>
<link rel="stylesheet" href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css"/>
`}
        showCopyButton={true}
      />
      <CodeBlockWithPlaceholders
        data-testid="codeBlock:body"
        title="app.component.html"
        alert={<QuacCodeAlert />}
      >
        {`\
<div>
  <h1>Zapier Elements Angular</h1>
  <${element}
`}
        {[...params.entries()]
          .filter(([key]) => key !== "id")
          .map(([key, val]) => {
            const camelKey = kebabToCamelCase(key);
            const indent = " ".repeat(4);
            if (val instanceof AppParamsPlaceholder) {
              return (
                <Fragment key={key}>
                  {indent}[{camelKey}]={"'"}
                  <CodePlaceholder value={val} />
                  {"'"}
                  {"\n"}
                </Fragment>
              );
            }
            // Angular uses `[key]='JS_EXPR'` for dynamic properties. You can
            // use `key="HTML string"` for HTML attributes, but not DOM
            // properties. It's simpler if we just have them use DOM properties
            // for everything, I think.
            return `${indent}[${camelKey}]='${JSON.stringify(val)}'\n`;
          })}
        {`\
  ></${element}>
</div>
`}
      </CodeBlockWithPlaceholders>
      <CodeBlock
        title="app.module.ts"
        code={`\
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";

@NgModule({
  imports: [BrowserModule],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  // -*-*-*- Add this line -*-*-*-
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  // -*-*-*-*-*-*-*-*-*-*-*-*-*-*-
})
export class AppModule {}
`}
        showCopyButton={true}
      />
    </div>
  );
};
