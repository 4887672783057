/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag Fragment */

import { css, jsx } from "@emotion/react";
import { LabeledRadio, Text, TooltipWrapper } from "@zapier/design-system";
import debounce from "lodash.debounce";
import { Dispatch, useCallback, useEffect, useMemo, useRef } from "react";
import { mediaQueryExpr } from "src/utils/mediaQuery";
import { useMediaQuery } from "usehooks-ts";

const styleContainer = css({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  padding: "10px 20px",
  gap: 10,
});

const styleThemePicker = css({
  display: "flex",
  alignItems: "center",
  gap: 10,
  paddingRight: 20,
});

const styleColorPicker = css({
  display: "flex",
  alignItems: "center",
  gap: 10,
});

const lightModeBackground = "#ffffff";
const darkModeBackground = "#333333";

export interface ThemeSettingsProps {
  theme: "light" | "dark" | "auto";
  color: string;
  setTheme: Dispatch<"light" | "dark" | "auto">;
  setColor: Dispatch<string>;
}

/**
 * Pick a theme (light | dark | auto) and a background color to preview it with
 */
function ThemeSettings({
  theme,
  color,
  setTheme,
  setColor,
}: ThemeSettingsProps): JSX.Element {
  const isDarkMode = useMediaQuery(mediaQueryExpr.darkMode);
  const colorRef = useRef(color);
  const setColorRef = useRef(setColor);

  // `useDebounce` from `usehooks-ts` is a lot easier to work with, but it still
  // causes a re-render on each color change. These happen very quickly, so it
  // made the UI incredibly laggy. It's still slow to re-render at times, but at
  // least it will only update when you stop interacting. This way you never
  // notice the lag.
  const updateColor = useMemo(() => {
    return debounce(() => {
      setColorRef.current(colorRef.current);
    }, 300);
  }, []);

  useEffect(() => {
    setColorRef.current = setColor;
  }, [setColor]);

  const immediatelyPublishColor = useCallback(
    (newColor: string) => {
      colorRef.current = newColor;
      setColor(newColor);
    },
    [setColor],
  );

  // When the theme changes to auto or the system theme changes, update the
  // background color because we assume it is not going to work with the new
  // text
  useEffect(() => {
    if (theme === "auto") {
      if (isDarkMode) {
        immediatelyPublishColor(darkModeBackground);
      } else {
        immediatelyPublishColor(lightModeBackground);
      }
    }
  }, [theme, isDarkMode, immediatelyPublishColor]);

  return (
    <div css={styleContainer}>
      <div css={styleThemePicker}>
        <Text tag="p" type="Body2">
          Select theme
        </Text>
        <LabeledRadio
          name="themeOption"
          tabIndex={0}
          onChange={() => {
            setTheme("light");
            immediatelyPublishColor(lightModeBackground);
          }}
          checked={theme === "light"}
        >
          Light
        </LabeledRadio>
        <LabeledRadio
          name="themeOption"
          tabIndex={0}
          onChange={() => {
            setTheme("dark");
            immediatelyPublishColor(darkModeBackground);
          }}
          checked={theme === "dark"}
        >
          Dark
        </LabeledRadio>
        <TooltipWrapper content="Changes with user preferences">
          {({ childProps }) => (
            <div {...childProps}>
              <LabeledRadio
                name="themeOption"
                tabIndex={0}
                onChange={() => {
                  setTheme("auto");
                }}
                checked={theme === "auto"}
              >
                Auto
              </LabeledRadio>
            </div>
          )}
        </TooltipWrapper>
      </div>
      <div css={styleColorPicker}>
        <Text tag="p" type="Body2">
          <label
            id="previewBackgroundColorText"
            htmlFor="previewBackgroundColorPicker"
          >
            Preview background color
          </label>
        </Text>
        <TooltipWrapper
          position="west"
          content="Simulate your website background"
        >
          {({ childProps }) => (
            <input
              {...childProps}
              aria-labelledby="previewBackgroundColorText"
              data-testid="themeSettings:colorPicker"
              id="previewBackgroundColorPicker"
              onChange={(event) => {
                colorRef.current = event.target.value;
                updateColor();
              }}
              onBlur={() => {
                setColor(colorRef.current);
              }}
              type="color"
              value={color || colorRef.current}
            />
          )}
        </TooltipWrapper>
      </div>
    </div>
  );
}

export default ThemeSettings;
