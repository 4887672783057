/** @jsxRuntime classic */
/** @jsx jsx */

// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
//
// Please test this code sample in Codesandbox when updating it!
//
// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

import { jsx } from "@emotion/react";
import { Text } from "@zapier/design-system";
import { FC } from "react";
import CodeBlock from "src/components/CodeBlock";
import { CodeExampleProps, html, styleExampleLayout } from "./CodeExample";

export const CodeExampleScript: FC<CodeExampleProps> = ({ params }) => {
  const code = (() => {
    const url = new URL(
      "https://zapier.com/partner/embed/app-directory/wrapper.js",
    );
    for (const [key, val] of params) {
      url.searchParams.set(key, String(val));
    }
    return html`<script type="module" src="${url}"></script>`;
  })();
  return (
    <div css={styleExampleLayout}>
      <Text tag="h2" type="SectionHeader">
        The simplest solution
      </Text>
      <Text tag="p">
        For server-side rendered HTML pages, add the <strong>script tag</strong>{" "}
        inside the <code>&lt;body&gt;</code> element where you want the App
        Directory Element to display.
      </Text>
      <CodeBlock
        title="Put this in the body where you want the element"
        code={code}
        showCopyButton={true}
      />
    </div>
  );
};
