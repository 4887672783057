/** @jsxRuntime classic */
/** @jsx jsx */

// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
//
// Please test this code sample in Codesandbox when updating it!
//
// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

import { jsx } from "@emotion/react";
import { Text } from "@zapier/design-system";
import { FC, Fragment } from "react";
import { kebabToCamelCase } from "src/utils/kebabToCamelCase";
import { CodeExampleProps, styleExampleLayout } from "./CodeExample";
import { CodeBlockWithPlaceholders } from "../CodeBlockWithPlaceholders";
import { QuacCodeAlert } from "./QuacCodeAlert";
import { CodePlaceholder } from "./CodePlaceholder";

export const CodeExampleJS: FC<CodeExampleProps> = ({ params, element }) => {
  return (
    <div css={styleExampleLayout}>
      <Text tag="h2" type="SectionHeader">
        A 100% vanilla JS solution
      </Text>
      <Text tag="p">
        Zapier Elements can be used with vanilla JS since they are custom
        elements (web components). Use the <strong>JavaScript</strong> module
        snippet to add a <code>&lt;{element}&gt;</code> to your page.
      </Text>
      <CodeBlockWithPlaceholders
        data-testid="codeBlock:body"
        title="JavaScript"
        alert={<QuacCodeAlert />}
      >
        {`\
// Load JS
const script = document.createElement("script");
script.type = "module";
script.src = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
document.head.appendChild(script);

// Load CSS
const stylesheet = document.createElement("link");
stylesheet.rel = "stylesheet";
stylesheet.href = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";
document.head.appendChild(stylesheet);

// Create and display ${element}
const element = document.createElement("${element}");
`}
        {[...params.entries()]
          .filter(([key]) => key !== "id")
          .map(([key, val]) => {
            const camelKey = kebabToCamelCase(key);
            return (
              <Fragment key={key}>
                element{"."}
                {camelKey}
                {" = "}
                <CodePlaceholder value={val} />
                {";\n"}
              </Fragment>
            );
          })}
        {`\
const container = document.querySelector("#zapier-container") || document.body;
container.appendChild(element);
`}
      </CodeBlockWithPlaceholders>
    </div>
  );
};
