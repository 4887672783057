/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag Fragment */

import { css, jsx } from "@emotion/react";
import { Colors, Link, Text } from "@zapier/design-system";

const styleBase = css({
  border: `1px solid ${Colors.GrayWarm5}`,
  borderRadius: 10,
  background: Colors.GrayWarm2,
  padding: 10,
});

const styleGrid = css({
  display: "grid",
  gap: 25,
  gridTemplateColumns: "1fr 1fr",
  // When the container is over 729px we have enough space to display both text and video in the same row
  "@container quacBanner (max-width: 729px)": {
    gridTemplateColumns: "1fr",
  },
});

const styleVideo = css({
  width: "100%",
  height: "auto",
  boxShadow: "0 4px 12px 0 rgb(0 0 0 / 20%)",
  borderRadius: "5px",
});

export default function QuacBanner() {
  return (
    <div css={[styleGrid, styleBase]}>
      <div>
        <Text margin="0px" tag="h2" type="Body4">
          Remove friction for your users with Quick Account Creation
        </Text>
        <Text margin="15px 0px" tag="p" type="Body1">
          Provide your users’ information to the Zapier embed so that they can{" "}
          <Link
            href="https://platform.zapier.com/embed/quick-account-creation"
            target="_blank"
          >
            bypass signing up for Zapier
          </Link>{" "}
          and jump right into creating a Zap with your integration.
        </Text>
        <Text margin="15px 0px" tag="p" type="Body1">
          Quick Account Creation is recommended but not required.
        </Text>
        <Text margin="15px 0px" tag="p" type="Body1">
          See how Adalo used Quick Account Creation and{" "}
          <Link
            href="https://zapier.com/blog/adalo-user-experience-with-zapier"
            target="_blank"
          >
            raised signups for their integration by 40%.
          </Link>
        </Text>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <video
          css={styleVideo}
          loop={true}
          controls={true}
          width={1920}
          height={1080}
          preload="auto"
          poster="https://cdn.zappy.app/1f21dbddbc570407248253c13859a5d9.png"
        >
          <source
            src="https://cdn.zappy.app/1f21dbddbc570407248253c13859a5d9.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
}
