/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag Fragment */
import { css, jsx } from "@emotion/react";
import { Button, Spacer, Text } from "@zapier/design-system";

import mediaQuery from "src/utils/mediaQuery";
import { useWorkflowElement } from "./hooks/useWorkflowElement";
import { Fragment } from "react";

const styleGetTheCode = css`
  padding: 0 5px 10px;

  ${mediaQuery.large} {
    padding: 0 5px 20px;
  }
`;

export function SidebarHeader() {
  const workflowElement = useWorkflowElement();
  const mode =
    workflowElement.elementConfig?.mode ?? workflowElement.defaultConfig.mode;

  return (
    <div css={styleGetTheCode}>
      {mode === "editing" && (
        <Fragment>
          <Button
            disabled={!workflowElement.elementConfig?.clientId}
            id="generate-code"
            isFullWidth={true}
            onClick={() => workflowElement.setGeneratorProperty("mode", "done")}
            size="large"
          >
            Generate code
          </Button>
          <Spacer height={20} />
          <Button
            color="tertiary"
            onClick={() => {
              workflowElement.setGeneratorProperty("serviceSlug", "");
            }}
            iconBefore="arrowBigLeft"
            size="large"
            isFullWidth
            data-testid="fze:goBackToPlugAndPlayButton"
          >
            Choose another app
          </Button>
          <Spacer height={30} />
          <Text tag="h2" type="SectionHeader" color="GrayWarm9">
            What do you want to include?
          </Text>
        </Fragment>
      )}
      {mode === "done" && (
        <Button
          disabled={!workflowElement.elementConfig?.clientId}
          id="done"
          isFullWidth={true}
          size="large"
          color="secondary"
          onClick={() =>
            workflowElement.setGeneratorProperty("mode", "editing")
          }
        >
          Edit
        </Button>
      )}
    </div>
  );
}
