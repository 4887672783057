/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag Fragment */
import { SerializedStyles, jsx } from "@emotion/react";
import { Text, ToggleSwitch } from "@zapier/design-system";
import { useWorkflowElement } from "./hooks/useWorkflowElement";

interface YourUserZapsToggleProps {
  containerClassName: SerializedStyles;
  contentWrapperClassName: SerializedStyles;
}

export function YourUserZapsToggle({
  containerClassName,
  contentWrapperClassName,
}: YourUserZapsToggleProps) {
  const workflowElement = useWorkflowElement();
  const manageZapsDisplay =
    workflowElement.elementConfig?.manageZapsDisplay ??
    workflowElement.defaultConfig.manageZapsDisplay;

  return (
    <div css={containerClassName}>
      <div css={contentWrapperClassName}>
        <Text color="GrayWarm10" fontWeight={600} tag="h2" type="Body4">
          Your user&apos;s Zaps
        </Text>
        <div data-testid="wfe:manageZapsDisplaySwitch">
          <ToggleSwitch
            checked={manageZapsDisplay !== false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              workflowElement.setGeneratorProperty(
                "manageZapsDisplay",
                event.target.checked,
              )
            }
          />
        </div>
      </div>
    </div>
  );
}
