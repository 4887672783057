/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag Fragment */
import { SerializedStyles, jsx } from "@emotion/react";
import { Text, ToggleSwitch } from "@zapier/design-system";
import { useWorkflowElement } from "./hooks/useWorkflowElement";

interface IntroToZapierProps {
  containerClassName: SerializedStyles;
  contentWrapperClassName: SerializedStyles;
}

export function IntroToZapierToggle({
  containerClassName,
  contentWrapperClassName,
}: IntroToZapierProps) {
  const workflowElement = useWorkflowElement();
  const introCopyDisplay =
    workflowElement.elementConfig?.introCopyDisplay ??
    workflowElement.defaultConfig.introCopyDisplay;

  const embedDocumentationURL = new URL(
    "/guides/workflow-element/workflow-element",
    "https://docs.api.zapier.com",
  );
  {
    // This weird new type of URL fragment isn't supported in Firefox and doesn't
    // have any good APIs for manipulating it. So we have to resort to encoding
    // the values directly.
    //
    // https://caniuse.com/url-scroll-to-text-fragment
    const value = encodeURIComponent("The preview shows what your embed");
    embedDocumentationURL.hash = `:~:text=${value}`;
  }

  return (
    <div css={containerClassName}>
      <div css={contentWrapperClassName}>
        <Text color="GrayWarm10" fontWeight={600} tag="h2" type="Body4">
          Intro to Zapier
        </Text>
        <div data-testid="wfe:introToZapierSwitch">
          <ToggleSwitch
            checked={introCopyDisplay}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              workflowElement.setGeneratorProperty(
                "introCopyDisplay",
                event.target.checked,
              )
            }
          />
        </div>
      </div>
    </div>
  );
}
