/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag Fragment */

import { css, jsx } from "@emotion/react";
import { Colors, Shadows } from "@zapier/design-system";

const stylePreviewFrame = css`
  box-shadow: ${Shadows.elevation10};
  margin: 0 auto;
  max-width: 1024px;
  border-radius: 10px;
`;

const stylePreviewFrameLocus = css`
  border: 1px solid ${Colors.GrayWarm4};
  border-bottom: none;
  display: grid;
  place-items: center;
  grid-template-columns: 120px 1fr 120px;
  height: 50px;
  border-radius: 10px 10px 0 0;
  background: #e2e2e2;
`;

const stylePreviewFrameContent = (color: string) => css`
  border-top: none;
  border: 1px solid ${Colors.GrayWarm4};
  background: ${color};
  padding: 20px;
  border-radius: 0 0 10px 10px;
`;

const styleMacCircleContainer = css`
  justify-self: start;
  margin-left: 15px;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 20px);
`;

const styleMacCircle = (color: string) => css`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: ${color};
  border: 1px solid rgb(0 0 0 / 10%);
  box-sizing: border-box;
`;

const styleUrlBar = css`
  user-select: none;
  background: white;
  width: 100%;
  height: 30px;
  margin: 10px;
  display: grid;
  place-items: center;
  border-radius: 5px;
  color: ${Colors.GrayWarm9};
  border: 1px solid rgb(0 0 0 / 15%);
  box-sizing: border-box;
`;

interface BrowserUIProps {
  children: JSX.Element;
  bgColor: string;
}

function BrowserUI({ bgColor, children }: BrowserUIProps): JSX.Element {
  return (
    <div data-testid="browserUI:previewFrame" css={stylePreviewFrame}>
      <div css={stylePreviewFrameLocus}>
        <div css={styleMacCircleContainer}>
          <div css={styleMacCircle("#E96E4C")} />
          <div css={styleMacCircle("#E5A935")} />
          <div css={styleMacCircle("#85C33D")} />
        </div>
        <div css={styleUrlBar}>your-product.com</div>
      </div>
      <div
        data-testid="browserUI:previewContent"
        css={stylePreviewFrameContent(bgColor)}
      >
        {children}
      </div>
    </div>
  );
}

export default BrowserUI;
