/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { Colors } from "@zapier/design-system";
import { AppParamsItem, AppParamsPlaceholder } from "./CodeExample";

const stylePlaceholder = css`
  color: ${Colors.Yellow2};
  background: ${Colors.GrayWarm10};
  padding: 2px 1ch;
  border-radius: 4px;
  font-style: italic;
  vertical-align: middle;
`;

interface CodePlaceholderProps {
  value: AppParamsItem;
}

export function CodePlaceholder({ value }: CodePlaceholderProps) {
  // Add invisible braces around the code so that if users forget to replace the
  // values they are more likely to notice and we can ignore them in QuAC.
  // `visiblity: hidden` removes them visually but keeps them from the
  // copy/paste.
  if (value instanceof AppParamsPlaceholder) {
    return (
      <span>
        {'"'}
        <span css={stylePlaceholder}>{value.example}</span>
        {'"'}
      </span>
    );
  }
  return <span>{JSON.stringify(value)}</span>;
}
