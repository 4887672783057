/** @jsxRuntime classic */
/** @jsx jsx */

// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*
//
// Please test this code sample in Codesandbox when updating it!
//
// *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*

import { jsx } from "@emotion/react";

import { Text } from "@zapier/design-system";
import { Fragment } from "react";
import CodeBlock from "src/components/CodeBlock";
import { CodeBlockWithPlaceholders } from "src/components/CodeBlockWithPlaceholders";
import {
  CodeExampleProps,
  styleExampleLayout,
} from "src/components/generator/CodeExample";
import { QuacCodeAlert } from "src/components/generator/QuacCodeAlert";
import { CodePlaceholder } from "./CodePlaceholder";

export function CodeExampleHTML({ params, element }: CodeExampleProps) {
  const headScript = `\
<script type="module" src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js"></script>
<link rel="stylesheet" href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css"/>
`;
  const realParams = [...params.entries()].filter(([key]) => key !== "id");
  return (
    <div css={styleExampleLayout}>
      <Text tag="h2" type="SectionHeader">
        A solution for server side HTML
      </Text>
      <Text tag="p">
        Zapier Elements can be used with HTML since they are custom elements
        (web components). Add the <strong>Head</strong> snippet to the{" "}
        <code>&lt;head&gt;</code> and use the <strong>Body</strong> snippet to
        add a <code>&lt;{element}&gt;</code> to your page.
      </Text>
      <CodeBlock
        data-testid="codeBlock:head"
        title="Head"
        code={headScript}
        showCopyButton={true}
      />
      <CodeBlockWithPlaceholders
        data-testid="codeBlock:body"
        title="Body"
        alert={<QuacCodeAlert />}
      >
        {"<"}
        {element}
        {"\n"}
        {realParams.map(([key, val]) => {
          const indent = " ".repeat(2);
          return (
            <Fragment key={key}>
              {indent}
              {key}
              {"="}
              <CodePlaceholder value={val} />
              {"\n"}
            </Fragment>
          );
        })}
        {"></"}
        {element}
        {">"}
      </CodeBlockWithPlaceholders>
    </div>
  );
}
