/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag Fragment */
import { SerializedStyles, jsx } from "@emotion/react";
import { Text, ToggleSwitch } from "@zapier/design-system";
import { useWorkflowElement } from "./hooks/useWorkflowElement";

interface GuessZapToggleProps {
  containerClassName: SerializedStyles;
  contentWrapperClassName: SerializedStyles;
}

export function GuessZapToggle({
  containerClassName,
  contentWrapperClassName,
}: GuessZapToggleProps) {
  const workflowElement = useWorkflowElement();
  const guessZapDisplay =
    workflowElement.elementConfig?.guessZapDisplay ??
    workflowElement.defaultConfig.guessZapDisplay;

  return (
    <div css={containerClassName}>
      <div css={contentWrapperClassName}>
        <Text color="GrayWarm10" fontWeight={600} tag="h2" type="Body4">
          Guess Zap
        </Text>
        <div data-testid="wfe:guessZapDisplaySwitch">
          <ToggleSwitch
            checked={guessZapDisplay !== false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              workflowElement.setGeneratorProperty(
                "guessZapDisplay",
                event.target.checked,
              )
            }
          />
        </div>
      </div>
    </div>
  );
}
