/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import {
  Alert,
  Button,
  ButtonNav,
  ButtonNavItemType,
  LabeledCheckbox,
  Link,
  Spacer,
  Spinner,
  Text,
} from "@zapier/design-system";
import { Fragment, useState } from "react";
import mediaQuery from "src/utils/mediaQuery";
import { waitForPopupCloseRegular } from "src/utils/waitForPopupClose";
import useSWR from "swr";
import { CodeExampleAngular } from "./CodeExampleAngular";
import { CodeExampleHTML } from "./CodeExampleHTML";
import { CodeExampleJS } from "./CodeExampleJS";
import { CodeExampleReact } from "./CodeExampleReact";
import { CodeExampleScript } from "./CodeExampleScript";
import { CodeExampleVue } from "./CodeExampleVue";
import QuacBanner from "../QuacBanner";

export const html = String.raw;

export const styleExampleLayout = css({
  display: "grid",
  gap: 20,
  paddingTop: 40,
  [mediaQuery.medium]: {
    paddingRight: 80,
    paddingLeft: 80,
  },
});

const styleQuacContainer = css({
  containerName: "quacBanner",
  containerType: "inline-size",
});

const stylePadding = css({
  [mediaQuery.medium]: {
    paddingRight: 80,
    paddingLeft: 80,
  },
});

const styleFlexCenter = css({
  display: "flex",
  justifyContent: "center",
});

const styleFlexRight = css({
  width: "fit-content",
  minWidth: "10ch",
  marginInlineStart: "auto",
  display: "flex",
});

export class AppParamsPlaceholder {
  constructor(public example: string) {}

  toString() {
    return this.example;
  }

  valueOf() {
    return this.toString();
  }
}

export type AppParamsItem = string | number | AppParamsPlaceholder;

export type AppParams = Map<string, AppParamsItem>;

export interface CodeExampleProps {
  serviceSlug?: string;
  integrationID?: string;
  params: AppParams;
  element:
    | "zapier-app-directory"
    | "zapier-zap-manager"
    | "zapier-zap-templates"
    | "zapier-full-experience"
    | "zapier-workflow";
  shouldShowQuacBanner: boolean;
}

export default function GeneratorCodeExample({
  params,
  element,
  integrationID,
  serviceSlug,
  shouldShowQuacBanner,
}: CodeExampleProps) {
  const items: ButtonNavItemType[] = [
    { key: "script", text: "Script" },
    { key: "html", text: "HTML" },
    { key: "js", text: "Vanilla JS" },
    { key: "react", text: "React" },
    { key: "angular", text: "Angular" },
    { key: "vue", text: "Vue.js 2" },
  ].filter((item) => {
    // Only the ADE has a script tag integration right now
    return !(element !== "zapier-app-directory" && item.key === "script");
  });
  const allowlistDomainsRequired =
    element === "zapier-full-experience" || element === "zapier-workflow";
  const [selectedItem, setSelectedItem] = useState<ButtonNavItemType>(items[0]);
  const CodeComponent =
    {
      script: CodeExampleScript,
      html: CodeExampleHTML,
      js: CodeExampleJS,
      react: CodeExampleReact,
      angular: CodeExampleAngular,
      vue: CodeExampleVue,
    }[selectedItem.key] ?? CodeExampleHTML;
  const [showDomainWarningScreen, setShowDomainWarningScreen] = useState(
    allowlistDomainsRequired,
  );
  const [domainWarningAcknowledged, setDomainWarningAcknowledged] =
    useState(false);
  const [popupClosedTimestamp, setPopupClosedTimestamp] = useState(0);

  const developerPlatformURL = new URL(
    `/app/${integrationID}/embed/settings/domains`,
    process.env.NEXT_PUBLIC_DEVELOPER_PLATFORM_ORIGIN,
  );

  const allowlistDomainsURL = new URL(
    "/api/v4/oauth/applications/",
    process.env.NEXT_PUBLIC_ZAPIER_ORIGIN,
  );
  allowlistDomainsURL.searchParams.set("service_slug", serviceSlug || "");
  allowlistDomainsURL.searchParams.set("t", String(popupClosedTimestamp));
  const allowlistDomains = useSWR(
    allowlistDomainsRequired ? allowlistDomainsURL.href : undefined,
    async (url): Promise<string[]> => {
      try {
        const resp = await fetch(url);
        if (!resp.ok) {
          return [];
        }
        const data = await resp.json();
        return data?.results?.[0]?.allowlist_domains ?? [];
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return [];
      }
    },
  );

  async function editAllowlistDomains() {
    // Try to use a popup window, but fall back to a new tab if it's
    // blocked by the browser.
    const popup = window.open(
      developerPlatformURL.href,
      "_blank",
      "width=1280,height=1024",
    );
    if (!popup) {
      alert("Please allow popups to continue.");
      return;
    }
    await waitForPopupCloseRegular(popup);
    setPopupClosedTimestamp(Date.now());
  }

  // The ADE/ZTE don't require allowlist domains to be set up
  if (!allowlistDomainsRequired) {
    return (
      <Fragment>
        {shouldShowQuacBanner && (
          <Fragment>
            <div css={[stylePadding, styleQuacContainer]}>
              <QuacBanner />
            </div>
            <Spacer height={40} />
          </Fragment>
        )}
        <div css={stylePadding}>
          <ButtonNav
            align="left"
            aria-label="Code to embed navigation"
            items={items}
            onChooseItem={(item, event) => {
              setSelectedItem(item);
              event.preventDefault();
            }}
            selectedItemKey={selectedItem.key}
            wrap
          />
        </div>
        <CodeComponent
          params={params}
          element={element}
          shouldShowQuacBanner={shouldShowQuacBanner}
        />
      </Fragment>
    );
  }

  // Loading spinner while we check the allowlist domains
  if (!allowlistDomains.data) {
    return (
      <Fragment>
        <div css={styleFlexCenter}>
          <Spacer height={40} />
          <Spinner size="large" />
        </div>
      </Fragment>
    );
  }

  // Show the currently allowed domains list if it's not empty
  if (showDomainWarningScreen && allowlistDomains.data.length > 0) {
    return (
      <Fragment>
        <Text tag="h2" type="SectionHeader" color="GrayWarm9">
          1. Please double check where you will embed the Zapier experience
        </Text>
        <Spacer height={20} />
        <Text tag="p" type="Body1" color="GrayWarm9">
          You have shared the following domain(s) as places you will embed
          Zapier:
        </Text>
        <Spacer height={20} />
        <ul css={{ paddingLeft: "1.5em" }}>
          {allowlistDomains.data.map((domain, i) => {
            return (
              <li key={i}>
                <Text
                  tag="p"
                  type="Body1"
                  color="GrayWarm9"
                  fontWeight={600}
                  margin="10px 0"
                >
                  {domain}
                </Text>
              </li>
            );
          })}
        </ul>
        <Spacer height={20} />
        <Text tag="p" type="Body1" color="GrayWarm9">
          If you want to make changes, you can{" "}
          <Link onClick={editAllowlistDomains}>manage the domains</Link>.
        </Text>
        <Spacer height={20} />
        <Text tag="p" type="Body1" color="GrayWarm9">
          Note: This step is required because the embed uses an iframe, which is
          blocked by default due to security.
        </Text>
        <Spacer height={20} />
        <div css={styleFlexRight}>
          <Button
            color="primary"
            size="large"
            isFullWidth
            onClick={() => {
              setShowDomainWarningScreen(false);
            }}
          >
            Next
          </Button>
        </div>
      </Fragment>
    );
  }

  // Show warning about allowlist domains being REQUIRED to use the FZE
  if (showDomainWarningScreen) {
    return (
      <Fragment>
        <Text tag="h2" type="SectionHeader" color="GrayWarm9">
          1. Tell us where you will embed the Zapier experience
        </Text>
        <Spacer height={30} />
        <Text tag="p" type="Body1" color="GrayWarm9">
          To embed the Workflow Element, please share the list of domains you
          intend to embed Zapier within and we&apos;ll permit these specific
          domains.
        </Text>
        <Spacer height={30} />
        <Text tag="p" type="Body1" color="GrayWarm9">
          Note: This step is required because the embed uses an iframe, which is
          blocked by default due to security.
        </Text>
        <Spacer height={30} />
        <Button color="primary" onClick={editAllowlistDomains}>
          Add domains
        </Button>
        <Spacer height={30} />
        <LabeledCheckbox
          tabIndex={0}
          checked={domainWarningAcknowledged}
          onChange={(event) => {
            const checkbox = event.target as HTMLInputElement;
            setDomainWarningAcknowledged(checkbox.checked);
          }}
        >
          I will add domains later. I understand that the embed will not work if
          I do not add domains.
        </LabeledCheckbox>
        <Spacer height={30} />
        <Alert>
          You can add domains in the {}
          <Link onClick={editAllowlistDomains}>Developer Platform</Link>.
        </Alert>
        <Spacer height={30} />
        <div css={styleFlexRight}>
          <Button
            color="primary"
            disabled={!domainWarningAcknowledged}
            size="large"
            isFullWidth
            onClick={() => {
              setShowDomainWarningScreen(false);
            }}
          >
            Next
          </Button>
        </div>
      </Fragment>
    );
  }

  // Show code generator screen with a "Back" button to go back to the allowlist
  // domain screens...
  return (
    <Fragment>
      <Button
        color="secondary"
        iconBefore="arrowBigLeft"
        onClick={() => {
          setShowDomainWarningScreen(true);
        }}
      >
        Back
      </Button>
      <div css={styleExampleLayout}>
        <Text
          tag="h2"
          type="SectionHeader"
          color="GrayWarm9"
          margin="0 0 30px 0"
        >
          2. Embed code in your product
        </Text>
      </div>
      {shouldShowQuacBanner && (
        <Fragment>
          <div css={[stylePadding, styleQuacContainer]}>
            <QuacBanner />
          </div>
          <Spacer height={40} />
        </Fragment>
      )}
      <div css={stylePadding}>
        <ButtonNav
          align="left"
          aria-label="Code to embed navigation"
          items={items}
          onChooseItem={(item, event) => {
            setSelectedItem(item);
            event.preventDefault();
          }}
          selectedItemKey={selectedItem.key}
          wrap
        />
      </div>
      <CodeComponent
        params={params}
        element={element}
        shouldShowQuacBanner={shouldShowQuacBanner}
      />
    </Fragment>
  );
}
