/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { Colors, Link, Text, ZapierLogo } from "@zapier/design-system";
import { ZAPIER_ORIGIN } from "src/utils/env";

const styleHeader = css`
  display: flex;
  border-bottom: 1px solid ${Colors.GrayWarm3};
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-inline: 40px;
  min-height: 56px;
`;

const styleLinkContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-left: 1px solid ${Colors.GrayWarm3};
  padding: 20px 25px;

  & a {
    text-decoration: none !important;
  }
`;

interface PlugAndPlayHeaderProps {
  isWorkflowElement?: boolean;
}

export default function PlugAndPlayHeader({
  isWorkflowElement = false,
}: PlugAndPlayHeaderProps): JSX.Element {
  const zapierOrigin = process.env.NEXT_PUBLIC_ZAPIER_ORIGIN;
  const classicURL = new URL(
    "/partner/embed/app-directory/create",
    zapierOrigin,
  );
  classicURL.searchParams.set("utm_source", "zapier_marketing_website");
  classicURL.searchParams.set("utm_medium", "embed_experience");
  classicURL.searchParams.set("utm_campaign", "classic_ui");

  return (
    <header css={styleHeader}>
      <a aria-label="Zapier homepage" href={ZAPIER_ORIGIN}>
        <ZapierLogo
          height={28}
          // @ts-expect-error Zinnia doesn't allow this, but it's correct and good
          width="auto"
        />
      </a>
      {!isWorkflowElement && (
        <div css={styleLinkContainer}>
          <Text type="Body3">
            <Link
              href={classicURL.href}
              data-testid="plugAndPlayHeader:SwitchClassicUI"
            >
              Switch back to Classic UI
            </Link>
          </Text>
        </div>
      )}
    </header>
  );
}
